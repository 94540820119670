import { createBrowserRouter } from "react-router-dom";
import Home from "../pages";
import VittaHyaluronic from "../pages/vitta-hyaluronic";
import VitaProNobis from "../pages/vita-pro-nobis";
import FormulaImagemEstilo from "../pages/formula-imagem-estilo";
import JornadaAutodidata from "../pages/jornada-autodidata";
import MestreEmDoisTempos from "../pages/mestre-em-2-tempos";
import AcademiaDePortugues from "../pages/academia-de-portugues";
import CursoDeHidroponia from "../pages/curso-de-hidroponia";
import FormacaoEletricistaResidencial from "../pages/eletricista-residencial";
import Maxpe from "../pages/4maxpe";

export default function Routes() {
  const router = createBrowserRouter([
    { path: "/", element: <Home /> },
    { path: "/vitta-hyaluronic", element: <VittaHyaluronic /> },
    { path: "/vita-pro-nobis", element: <VitaProNobis /> },
    { path: "/formula-imagem-e-estilo", element: <FormulaImagemEstilo /> },
    { path: "/a-jornada-do-autodidata", element: <JornadaAutodidata /> },
    { path: "/mestre-em-dois-tempos", element: <MestreEmDoisTempos /> },
    { path: "/academia-de-portugues", element: <AcademiaDePortugues /> },
    { path: "/curso-de-hidroponia", element: <CursoDeHidroponia /> },
    { path: "/formacao-eletricista-residencial", element: <FormacaoEletricistaResidencial /> },
    { path: "/4maxpe", element: <Maxpe /> },
  ]);
  return router;
}