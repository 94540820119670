import React from "react";
import Modal from "../../components/Modal";

export default function MestreEmDoisTempos() {
  const linkAffiliate = "https://go.hotmart.com/T96267649X";

  return (
    <>
      <div 
        className={`w-full h-screen flex justify-center items-center bg-[url(https://purchasetoday.s3.us-east-1.amazonaws.com/melhores-descontos/img/mestre+em+2+tempos/mobile.png)] lg:bg-[url(https://purchasetoday.s3.us-east-1.amazonaws.com/melhores-descontos/img/mestre+em+2+tempos/desktop.png)] bg-cover bg-center bg-no-repeat`}
      ></div>
      <Modal link={linkAffiliate} />
    </>
  )
}