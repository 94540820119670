import React from "react";

export default function Modal(props: any) {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex justify-center items-end">
      <div className="w-[90%] md:w-[80%] lg:w-[50%] min-h-[300px] md:min-h-[230px] bg-white shadow-md rounded-2xl mb-[70px] md:mb-5 flex flex-col justify-center items center px-8 pb-2">
        <a
          className="btn-lp text-blue-400 text-[16px] w-full h-4 mt-2 font-nunito text-right"
          href={props.link}
        >
          Fechar
        </a>
        <h1 
          className="font-nunito text-2xl font-bold mb-3"
        >
          Política de Cookies
        </h1>
        <p
          className="font-nunito  mb-3"
        >Este site usa cookies para personalizar conteúdo, anúncios, fornecer recursos de mídia social e analisar nosso tráfego. Ao clicar em aceitar, você concorda com o uso de cookies. Para mais informações, visite nossa <a className="btn-lp cursor-pointer text-blue-500" href={props.link}>Política de Cookies</a></p>
        <div 
          className="w-full h-[50px] mb-3"
        >
          <a
            className="btn-lp bg-blue-500 w-[150px] h-10 text-white text-bold text-[20px] font-nunito font-bold rounded-xl mt-3 shadow-lg shadow-blue-200 text-center flex justify-center items-center"
            href={props.link}
          >
            Aceitar
          </a>
        </div>
      </div>
    </div>
  )
}