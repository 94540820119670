import React from "react";
import Modal from "../../components/Modal";

export default function FormulaImagemEstilo() {
  const linkAffiliate = "https://go.hotmart.com/E96242102O";

  return (
    <>
      <div 
        className={`w-full h-screen flex justify-center items-center bg-[url(https://purchasetoday.s3.us-east-1.amazonaws.com/melhores-descontos/img/formula-imagem-estilo/mobile.png)] lg:bg-[url(https://purchasetoday.s3.us-east-1.amazonaws.com/melhores-descontos/img/formula-imagem-estilo/desktop.png)] bg-cover bg-center bg-no-repeat`}
      ></div>
      <Modal link={linkAffiliate} />
    </>
  )
}