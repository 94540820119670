import React from "react";
import Modal from "../../components/Modal";

export default function JornadaAutodidata() {
  const linkAffiliate = "https://go.hotmart.com/A96267726T";

  return (
    <>
      <div 
        className={`w-full h-screen flex justify-center items-center bg-[url(https://purchasetoday.s3.us-east-1.amazonaws.com/melhores-descontos/img/a+jornada+do+autodidata/mobile.png)] lg:bg-[url(https://purchasetoday.s3.us-east-1.amazonaws.com/melhores-descontos/img/a+jornada+do+autodidata/desktop.png)] bg-cover bg-center bg-no-repeat`}
      ></div>
      <Modal link={linkAffiliate} />
    </>
  )
}