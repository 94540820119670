import React from "react";
import Modal from "../../components/Modal";

export default function VitaProNobis() {
  const linkAffiliate = "https://ev.braip.com/campanhas/cpa/campgov1k";

  return (
    <>
      <div 
        className={`w-full h-screen flex justify-center items-center bg-[url(https://d3tb5hw0zfiw46.cloudfront.net/melhores-descontos/img/vita-pro-nobis/mobile.png)] lg:bg-[url(https://d3tb5hw0zfiw46.cloudfront.net/melhores-descontos/img/vita-pro-nobis/desktop.png)] bg-cover bg-center bg-no-repeat`}
      ></div>
      <Modal link={linkAffiliate} />
    </>
  )
}