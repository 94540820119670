import React from "react";
import Modal from "../../components/Modal";

export default function AcademiaDePortugues() {
  const linkAffiliate = "https://go.hotmart.com/R96242223O";

  return (
    <>
      <div 
        className={`w-full h-screen flex justify-center items-center bg-[url(https://d3tb5hw0zfiw46.cloudfront.net/melhores-descontos/img/academia-de-portugues/mobile.png)] lg:bg-[url(https://d3tb5hw0zfiw46.cloudfront.net/melhores-descontos/img/academia-de-portugues/desktop.png)] bg-cover bg-center bg-no-repeat`}
      ></div>
      <Modal link={linkAffiliate} />
    </>
  )
}