import React from "react";
import Modal from "../../components/Modal";

export default function VittaHyaluronic() {
  const linkAffiliate = "https://app.monetizze.com.br/r/ANL24784745";

  return (
    <>
      <div 
        className={`w-full h-screen flex justify-center items-center bg-[url(https://d3tb5hw0zfiw46.cloudfront.net/melhores-descontos/img/vitta-hyaluronic/vitta-hyaluronic-mobile.png)] lg:bg-[url(https://d3tb5hw0zfiw46.cloudfront.net/melhores-descontos/img/vitta-hyaluronic/vitta-hyaluronic-desktop.png)] bg-cover bg-center bg-no-repeat`}
      ></div>
      <Modal link={linkAffiliate} />
    </>
  )
}