import React from "react";
import Modal from "../../components/Modal";

export default function CursoDeHidroponia() {
  const linkAffiliate = "https://go.hotmart.com/L96267665L";

  return (
    <>
      <div 
        className={`w-full h-screen flex justify-center items-center bg-[url(https://purchasetoday.s3.us-east-1.amazonaws.com/melhores-descontos/img/hidroponia/mobile.png)] lg:bg-[url(https://purchasetoday.s3.us-east-1.amazonaws.com/melhores-descontos/img/hidroponia/desktop.png)] bg-cover bg-center bg-no-repeat`}
      ></div>
      <Modal link={linkAffiliate} />
    </>
  )
}